<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import TextType from "@shared/Form/TextType/TextType.svelte"
    import GPSMap from "./GPSMap/GPSMap.svelte"
    import {OpenStreetMapProvider} from "leaflet-geosearch"
    import {throttle} from "lodash-es"
    import {LatLng, LatLngTuple} from "leaflet"
    import {onMount} from "svelte"
    import SelectType from "@shared/Form/SelectType/SelectType.svelte"
    import axiosClient from "@utils/axiosClient"
    import tooltip from "@utils/Action/Tooltip"
    import {toast} from "@zerodevx/svelte-toast"

    export let entry: FormEntry

    let markerLatLng: LatLngTuple | LatLng | undefined = entry.children["gps"]?.value
            ? entry.children["gps"].value.split(",") as number[]
            : undefined
    let loadingAres = false

    const handleLoadAres = (): void => {
        if (!entry.children["taxNumber"].value) {
            return
        }

        if (loadingAres) {
            return
        }

        loadingAres = true

        axiosClient.get(`/ares/info/${ entry.children["taxNumber"].value }`).then(response => {
            if (!response.data) {
                return
            }

            if (entry.children["city"] && (response.data.city || response.data.city_district)) {
                entry.children["city"].value = response.data.city ?? response.data.city_district
            }

            if (entry.children["street"] && response.data.street) {
                let street = response.data.street
                if (response.data.house_number) {
                    street += " " + response.data.house_number
                }

                entry.children["street"].value = street
            }

            if (entry.children["zip"] && response.data.zip) {
                entry.children["zip"].value = response.data.zip
            }

            if (entry.children["companyName"] && response.data.company) {
                entry.children["companyName"].value = response.data.company
            }

            if (entry.children["vatNumber"] && response.data.tin) {
                entry.children["vatNumber"].value = response.data.tin

                if (entry.children["country"]) {
                    if (response.data.tin.startsWith("CZ")) {
                        entry.children["country"].value = "CZ"
                    } else {
                        entry.children["country"].value = "SK"
                    }
                }
            }

            toast.push({
                msg: "Údaje načteny",
                duration: 4000,
                dismissable: true,
                classes: ["msd-toast-success"],
                pausable: true,
            })
        }).catch(() => {
            toast.push({
                msg: "ARES nic nenašel",
                duration: 4000,
                dismissable: true,
                classes: ["msd-toast-warning"],
                pausable: true,
            })
        }).finally(() => loadingAres = false)
    }

    const provider = new OpenStreetMapProvider({
        params: {
            email: "info@mujstavebnidenik.cz",
            "accept-language": "cs",
            countrycodes: "cz",
            addressdetails: 1,
        },
    })

    const handleGeoSelect = (e: CustomEvent): void => {
        if (entry.disabled) {
            return
        }

        const rawAddress = e.detail?.location?.raw?.address
        if (rawAddress && entry.children) {
            // what a mess
            let street = rawAddress.street ?? rawAddress.road ?? rawAddress.village
            if (rawAddress.house_number) {
                if (street) {
                    street += " " + rawAddress.house_number
                } else {
                    street = rawAddress.house_number
                }
            }

            if (rawAddress.amenity) {
                if (street) {
                    street += ", " + rawAddress.amenity
                } else {
                    street = rawAddress.amenity
                }
            }

            if (entry.children["street"]) {
                entry.children["street"].value = street ?? ""
            }

            let city = rawAddress.suburb ?? rawAddress.city ?? rawAddress.village ?? rawAddress.town
            if (rawAddress.municipality) {
                if (city) {
                    city += ", "
                }
                city += rawAddress.municipality
            }

            if (entry.children["city"]) {
                entry.children["city"].value = city ?? ""
            }

            let zip = rawAddress.postcode
            if (entry.children["zip"]) {
                entry.children["zip"].value = zip ?? ""
            }

            if (entry.children["country"]) {
                let country = rawAddress.country_code
                if (country) {
                    entry.children["country"].value = country.toUpperCase()
                }
            }
        }
    }

    const handleInput = throttle((): void => {
        if (entry.disabled) {
            return
        }

        if (entry.children["gps"] === undefined) {
            return
        }

        let search = []
        if (entry.children["street"] && entry.children["street"].value) {
            search.push(entry.children["street"].value)
        }

        if (entry.children["city"] && entry.children["city"].value) {
            search.push(entry.children["city"].value)
        }

        if (entry.children["zip"] && entry.children["zip"].value) {
            search.push(entry.children["zip"].value)
        }

        if (search.length > 0) {
            provider.search({
                query: search.join(",")
            }).then(results => {
                const result = results[0] ?? undefined
                if (!result) {
                    return
                }

                entry.children["gps"].value = result.y + "," + result.x

                markerLatLng = [result.y, result.x]
            })
        }

    }, 2500, { trailing: true })

    onMount(() => {
        handleInput()
    })
</script>

<div class="flex flex-col gap-4">
    {#if entry.children["gps"]}
        <GPSMap entry={entry.children["gps"]} bind:markerLatLng={markerLatLng} on:geoSelect={handleGeoSelect}/>
    {/if}

    {#if entry.children["name"]}
        <TextType entry={entry.children["name"]}/>
        <TextType entry={entry.children["lastName"]}/>
    {/if}

    {#if entry.children["companyName"]}
        <TextType entry={entry.children["companyName"]}/>
        <div class="flex flex-row gap-4">
            <TextType on:input={() => entry.children['taxNumber'].value = entry.children['taxNumber'].value} entry={entry.children["taxNumber"]}/>
            <button class="bg-primary min-w-[193px] flex h-[40px] mt-auto rounded-md items-center justify-center text-center px-4 font-bold  {!entry.children['taxNumber'].value ? '!bg-neutral-300 text-white cursor-default' : 'hover:bg-black hover:text-white'} transition-colors text-neutral-800 tracking-wider "
                    on:click|preventDefault={handleLoadAres}
                    use:tooltip={{content: !entry.children['taxNumber'].value ? "Vyplńte IČO" : ""}}
            >
                {#if loadingAres}
                    Načítáme...
                {:else}
                    Načíst z ARES
                {/if}
            </button>
        </div>
        <TextType entry={entry.children["vatNumber"]}/>
    {/if}

    {#if entry.children["street"]}
        <TextType entry={entry.children["street"]} on:input={handleInput}/>
        <TextType entry={entry.children["city"]} on:input={handleInput}/>
        <TextType entry={entry.children["zip"]} on:input={handleInput}/>
    {/if}

    {#if entry.children["country"]}
        <SelectType entry={entry.children["country"]}/>
    {/if}
</div>
